.contactForm{
    margin-top: 25px;
}

.row.formRow {
    margin-bottom: 18px;
}

button.submit-btn {
    width: 180px;
    height: 40px;
    border-radius: 5px;
    background: #8e555c;
    color: white;
    border: 1px solid white;
}

P.succes{
    font-size: 19px;
    color: green;
    font-style: italic;
    padding: 25px;
}