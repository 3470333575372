.contact-form{
    background: #fff;
    margin-top: 8%;
    margin-bottom: 5%;
    width: 70%;
    margin-bottom: 120px;
}
.contact-form .form-control{
    border-radius:1rem;
}
.contact-image{
    text-align: center;
}
.contact-image img{
    border-radius: 6rem;
    width: 11%;
    margin-top: -3%;
    transform: rotate(29deg);
}
/* .contact-form form{
    padding: 14%;
} */
/* .contact-form form .row{
    margin-bottom: -7%;
} */
.contact-form h3{
    margin-bottom: 8%;
    margin-top: -10%;
    text-align: center;
    color:black;
    font-weight: 500;
    font-style: italic;
}
.contact-form .btnContact {
    width: 50%;
    border: none;
    border-radius: 1rem;
    padding: 1.5%;
    background: #8e555c;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
}
.btnContactSubmit
{
    width: 50%;
    border-radius: 1rem;
    padding: 1.5%;
    color: #fff;
    background-color: #0062cc;
    border: none;
    cursor: pointer;
}